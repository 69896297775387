import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LinkButtonPosition } from '@components/atoms/buttons/components/link-button/link-button.enum';
import { IconColor, IconSizeModifier, NextIcon } from '@components/atoms/visual/enums/icon.enum';
import { TableFieldProps } from '@components/organisms/next-table/enums/next-table.enum';
import { StatusMap } from '../../../../core/types/status.type';
import { GenericObject } from '@utils/models/Types';

@Component({
  selector: 'header-detail',
  templateUrl: './header-detail.component.html',
  styleUrl: './header-detail.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderDetailComponent {
  @Input() header?: string;
  @Input() headerKey?: string;
  @Input() status?: string;
  @Input() statusMap: GenericObject<StatusMap>;
  @Input() subTitle?: string;
  @Input() subTitleKey?: string;
  @Input() disabledSubTitle: boolean = true;
  @Input() iconType: NextIcon;
  @Input() iconColor?: IconColor = IconColor.BRAND_PRIMARY;
  @Input() iconSize?: IconSizeModifier = IconSizeModifier.REGULAR;
  @Input() iconPosition: LinkButtonPosition;
  protected readonly TableFieldProps = TableFieldProps;
}
