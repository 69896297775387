<div class="header-detail">
  <div *ngIf="header || headerKey" class="header-detail__header">
    <div class="header-detail__title">
      {{header || (headerKey! | translate)}}
      <span *ngIf="subTitle" class="header-detail__subtitle">
                <link-button [iconType]="iconType"
                             [iconColor]="iconColor!"
                             [iconSize]="iconSize!"
                             [iconPosition]="iconPosition"
                             [label]="subTitle || (subTitleKey! | translate)"
                             [isDisabled]="true"></link-button>
            </span>
    </div>
    <tag-status *ngIf="status" [status]="status" [statusMap]="statusMap"></tag-status>
  </div>
</div>

